


























































































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator'

import ThreeStateButtonGroup from '@/components/Controls/ThreeStateButtonGroup.vue'

import { filterFields } from './constants'


interface Filters extends Record<string, undefined | null | string | boolean> {
  moderation_period_from: undefined | string
  moderation_period_to: undefined | string
  can_be_moderated: null | boolean
  filter_moderated: null | boolean
  filter_patent: null | boolean
  filter_moderate_admin: null | boolean
  filter_moderate_client: null | boolean
}

const emptyFilters = {
  moderation_period_from: undefined,
  moderation_period_to: undefined,
  can_be_moderated: null,
  filter_moderated: null,
  filter_patent: null,
  filter_moderate_admin: null,
  filter_moderate_client: null,
}


@Component({
  components: {
    ThreeStateButtonGroup,
  },
})
export default class ModalFilter extends Vue {
  @Prop(Boolean) public readonly show: boolean | undefined

  public display: boolean = false

  public filters: Filters = {} as Filters


  // ---------------------------------------------------------------------------
  @Watch('show')
  public onOpened(nv: boolean) {
    if (nv) {
      filterFields.forEach((key: string) => {
        const value = this.$route.query[key]

        if (value === 'true') {
          this.$set(this.filters, key, true)
        }
        else if (value === 'false') {
          this.$set(this.filters, key, false)
        }
        else if (['moderation_period_from', 'moderation_period_to'].includes(key) && !!value) {
          this.$set(this.filters, key, value)
        }
        else {
          this.$set(this.filters, key, null)
        }
      })

      this.display = true
    }
  }

  public onChangeValue(key: string, value: undefined | null | string | boolean) {
    this.$set(this.filters, key, value)
  }

  public setFilter() {
    this._onCloseEmit(true)
  }

  public resetFilter() {
    this.filters = { ...emptyFilters }
    this._onCloseEmit(true)
  }

  public onClose() {
    this._onCloseEmit()
  }

  @Emit('close')
  private _onCloseEmit(emit = false) {
    this.display = false

    if (emit) {
      return this.filters
    }
  }
}
