import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { Dictionary } from 'vue-router/types/router'
import { faSmileBeam } from '@fortawesome/free-solid-svg-icons'

interface MerchantRegisterFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  object: any;
  form: any;
}

const $state: MerchantRegisterFormState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  form: null,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  inn: null,

  checked: false,
  check_error: false,

  exists: false,
  moderated: false,
  merchant_id: null,
}

export const $actions: ActionTree<MerchantRegisterFormState, RootState> = {
  initState({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('GENERATE_FORM_DATA')
      commit('LOADED_SUCCESS')
      resolve(state.object)
    })
  },

  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },

  checkInn({ commit, state }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const params: any = {
        inn: state.form.inn,
      }

      vm.$http
        .get(`/admins/rest/v1/merchants/check_inn`, { params })
        .then((response: AxiosResponse) => {
          commit('CHANGE_VALUE', { field: 'checked', newValue: true })
          commit('CHANGE_VALUE', { field: 'check_error', newValue: false })

          commit('CHANGE_VALUE', { field: 'exists', newValue: response.data.exists })
          commit('CHANGE_VALUE', { field: 'moderated', newValue: response.data.moderated })
          commit('CHANGE_VALUE', { field: 'merchant_id', newValue: response.data.merchant_id })

          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          commit('CHANGE_VALUE', { field: 'checked', newValue: false })
          commit('CHANGE_VALUE', { field: 'check_error', newValue: true })

          reject(error.response?.data)
        })
    })
  },
}

export const $mutations: MutationTree<MerchantRegisterFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },

  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }

    state.form.requisites_actual_address_tooltip_value = {}
  },

  GENERATE_FORM_DATA(state) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
    }
  },

  CHANGE_VALUE(state: MerchantRegisterFormState, { field, newValue }) {
    state.form[field] = newValue
  },
}

const $getters = {}

export const STORE_KEY = '$_merchant_register_form'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}

