import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import axios from 'axios';

interface MerchantListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
}

const $state: MerchantListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
}

export const $actions: ActionTree<MerchantListState, RootState> = {
  async loadData({ state, commit }, query) {
    try {
      commit('START_LOADING')

      const params: any = {}

      if (query.sortField) {
        params.order_by = query.sortField
        params.order_direction = query.sortDirection || 'asc'
      }

      params.page = query.page || 1

      if (query.name) {
        params.search = query.name
      }

      if (query.brand_name) {
        params.search_brand = query.brand_name
      }

      if (query.city_name) {
        params.search_city = query.city_name
      }

      if (query.filter_patent) {
        params.filter_patent = query.filter_patent
      }
      if (query.filter_moderated) {
        params.filter_moderated = query.filter_moderated
      }
      if (query.can_be_moderated) {
        params.can_be_moderated = query.can_be_moderated
      }
      if (query.filter_moderate_admin) {
        params.filter_moderate_admin = query.filter_moderate_admin
      }
      if (query.filter_moderate_client) {
        params.filter_moderate_client = query.filter_moderate_client
      }
      if (query.moderation_period_from) {
        params.moderation_period_from = query.moderation_period_from
      }
      if (query.moderation_period_to) {
        params.moderation_period_to = query.moderation_period_to
      }

      const response = await axios.get('/admins/rest/v1/merchants', { params })
      commit('SET_ITEMS_LIST', response.data)
      commit('LOADED_SUCCESS')
    } catch (error) {
      console.error('Fatal error', error.data)
      commit('LOADED_ERROR')
    }
  },
}

export const $mutations: MutationTree<MerchantListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },

  SET_ITEMS_LIST(state, data) {
    state.documents = data.merchants

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },
}

export const $getters: GetterTree<MerchantListState, RootState> = {}

export const STORE_KEY = '$_merchant_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
